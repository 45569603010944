exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-campaign-1-tsx": () => import("./../../../src/pages/campaign1.tsx" /* webpackChunkName: "component---src-pages-campaign-1-tsx" */),
  "component---src-pages-campaign-tsx": () => import("./../../../src/pages/campaign.tsx" /* webpackChunkName: "component---src-pages-campaign-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reward-tsx": () => import("./../../../src/pages/reward.tsx" /* webpackChunkName: "component---src-pages-reward-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

